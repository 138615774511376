// import MolaMfpAPI from '../clients/MolaMfpAPI';
import { db } from '@/main'

export default {
  state: {
    total_user: 0,
    total_user_today: 0,
    total_user_this_month: 0,
    total_prediction: 0,
    total_points_admin_won: 0,
    total_trial_points_won: 0,
    total_mola_subscriber: 0,
    total_not_mola_subscriber: 0,
    total_expired_mola_subscriber: 0,
    total_free_mola_subscriber: 0,
    eventList: [],
    eventListEmpty: false,
    inviteList: [],
    inviteListEmpty: false,
    duelSummaryData: [],
    predictionByEventSummary: [],
    emailNotification: [],
  },
  mutations: {
    setState(state, payload) {
      var keys = Object.keys(payload);
      keys.forEach((key) => {
        state[key] = payload[key];
      });
    },
  },
  actions: {
    async getAdminDashboard({ commit }) {
      let res = {
        status: 0,
        data: {},
      };
      const today = new Date();
      const cache =
        localStorage.getItem('mfp-admin-dashboard') === null
          ? null
          : JSON.parse(localStorage.getItem('mfp-admin-dashboard'));
      console.log('cache', cache);
      if (cache !== null) {
        if (cache.expired >= today.getTime()) {
          commit('setState', {
            total_user: cache.total_user,
            total_user_today: cache.total_user_today,
            total_user_this_month: cache.total_user_this_month,
            total_prediction: cache.total_prediction,
            total_points_admin_won: cache.total_points_admin_won,
            total_trial_points_won: cache.total_trial_points_won,
            total_mola_subscriber: cache.total_mola_subscriber,
            total_not_mola_subscriber: cache.total_not_mola_subscriber,
            total_expired_mola_subscriber: cache.total_expired_mola_subscriber,
            total_free_mola_subscriber: cache.total_free_mola_subscriber,
          });
          return;
        }
      }
      try {
        const response = await db
          .collection('config')
          .doc('admin_dashboard')
          .get();
        res.status = 200;
        res.data = response.data();
      } catch (err) {
        console.log(err);
        res = null;
      }
      console.log(res);
      if (res && res.status === 200) {
        const expiredDate = new Date().getTime() + 1000 * 3600;
        const payload = {
          total_user: res.data.total_user,
          total_user_today: res.data.total_user_today,
          total_user_this_month: res.data.total_user_this_month,
          total_prediction: res.data.total_prediction,
          total_points_admin_won: res.data.total_points_won,
          total_trial_points_won: res.data.total_trial_points_won,
          total_mola_subscriber: res.data.total_mola_subscriber,
          total_not_mola_subscriber: res.data.total_not_mola_subscriber,
          total_expired_mola_subscriber: res.data.total_expired_mola_subscriber,
          total_free_mola_subscriber: res.data.total_free_mola_subscriber,
          expired: expiredDate,
        };
        localStorage.setItem('mfp-admin-dashboard', JSON.stringify(payload));
        commit('setState', payload);
      } else {
        commit('setState', {
          total_user: 0,
          total_user_today: 0,
          total_user_this_month: 0,
          total_prediction: 0,
          total_points_admin_won: 0,
          total_trial_points_won: 0,
          total_mola_subscriber: 0,
          total_not_mola_subscriber: 0,
          total_expired_mola_subscriber: 0,
          total_free_mola_subscriber: 0,
        });
      }
    },
    async duelGetRealTimeEvent({ commit }, payload) {
      commit('setLoading', payload);
      let today = new Date().getTime();
      let query = await db
        .collection('events')
        .where('status', '==', 1) // check when status is 2
        .where('event_pub_end', '>=', today)
        .orderBy('event_pub_end', 'asc')
        .orderBy('order', 'asc')
        .limit(1);
      query.onSnapshot(
        (querySnapshot) => {
          if (querySnapshot.empty) {
            console.log('No matching Events.');
            commit('setState', { eventListEmpty: true });
            return;
          }
          var eventList = [];
          querySnapshot.forEach((doc) => {
            // console.log(doc.id, '=>', doc.data());
            var obj = doc.data();
            obj.doc_id = doc.id;
            eventList.push(obj);
            // var inArray = eventList.findIndex(item => item.id === obj.id) > -1
          });
          console.log('##### Event List loaded ######');
          console.log(eventList);
          commit('setState', { eventListEmpty: false });
          commit('setState', { eventList: eventList });
        },
        (err) => {
          console.log(`Encountered error: ${err}`);
        }
      );
    },
    async duelGetRealTimeInvites({ commit }, payload) {
      commit('setLoading', payload);
      console.log(payload);
      let query = await db
        .collection('duel_invitation')
        .where('event_doc_id', '==', payload.event_doc_id); // check when status is 2
      query.onSnapshot(
        (querySnapshot) => {
          if (querySnapshot.empty) {
            console.log('No matching Invites.');
            commit('setState', { inviteListEmpty: true });
            return;
          }
          var inviteList = [];
          querySnapshot.forEach((doc) => {
            // console.log(doc.id, '=>', doc.data());
            var obj = doc.data();
            obj.doc_id = doc.id;
            inviteList.push(obj);
            // var inArray = eventList.findIndex(item => item.id === obj.id) > -1
          });
          console.log('##### Invite List loaded ######');
          console.log(inviteList);
          commit('setState', { inviteListEmpty: false });
          commit('setState', { inviteList: inviteList });
        },
        (err) => {
          console.log(`Encountered error: ${err}`);
        }
      );
    },
    async duelGetSummaryReport({ commit }, payload) {
      commit('setLoading', payload);
      console.log(payload);

      let query = db
        .collection('duel_invitation')
        .where('event_doc_id', '==', payload.event_doc_id)
        .orderBy('status', 'desc');
      try {
        let duelSummaryData = [];
        const eventSnapshot = await query.get();
        if (!eventSnapshot.empty) {
          eventSnapshot.forEach((doc) => {
            let obj = doc.data();
            duelSummaryData.push(obj);
          });
          commit('setState', {
            duelSummaryData: duelSummaryData,
          });
          // console.log(mapEvent)
        }
      } catch (Err) {
        console.log(Err);
      }
    },
    async predictionGetSummaryReport({ commit }, payload) {
      let predictions = [];
      let queryPredictions = db
        .collection('predictions')
        .where('event_doc_id', '==', payload.event_doc_id)
        .where('results_processed', '>=', 0);
      try {
        const predictionSnapshot = await queryPredictions.get();
        if (!predictionSnapshot.empty) {
          predictionSnapshot.forEach((doc) => {
            const predictionData = doc.data();
            predictionData.prediction_id = doc.id;
            predictions.push(predictionData);
          });
          commit('setState', {
            predictionByEventSummary: predictions,
          });
        }
      } catch (Err) {
        console.log(Err);
      }
    },
    async getEmailLog({ commit }) {
      let query = db
        .collection('report_dashboard_log')
        .orderBy('status', 'desc');
      try {
        let emailNotification = [];
        const eventSnapshot = await query.get();
        if (!eventSnapshot.empty) {
          eventSnapshot.forEach((doc) => {
            let obj = doc.data();
            emailNotification.push(obj);
          });
          commit('setState', {
            emailNotification: emailNotification,
          });
          // console.log(mapEvent)
        }
      } catch (Err) {
        console.log(Err);
      }
    },
  },
  getters: {},
};
