<template>
  <div id="appfooter">
  <v-footer
      color="#252525"
      class="d-flex"
      padless
    >
      <v-row class="pt-6 px-4 px-md-8 my-6 mx-auto">
        <v-col cols="12" md="3" style="color:#FFF;" :align="'center'" class="d-flex align-center justify-center justify-md-start">
          <v-img
            :src="'/img/logos/mola_logo_dark.png'"
            style="max-width: 200px"
          ></v-img>


          <!-- <span @click="gotoLink('https://mola.tv/accounts/profile?tab=subscriptionPackage')"
               class="font-weight-bold my-4" 
               style="font-size: 18px; color:#FFF; cursor: pointer">
               {{ lang[getLanguage].FOOTER_TITLE_NEW }}
          </span> -->
         
          
        </v-col>

        <v-col cols="12" md="3" class="mx-auto">
          <!-- <span
            style="font-size: 22px; color:#FFF; cursor: pointer"
            class="font-weight-bold"
            @click="gotoLink('https://www.moladigital.com/en')"
            >{{ lang[getLanguage].ABOUT }}</span>
          <br />
          <br />
          <span
            style="font-size: 18px; color:#FFF; cursor: pointer"
            class="font-weight-normal"
            @click="openFileDialog('pp')"
            >{{ lang[getLanguage].MY_PROFILE_PRIVACY_POLICY }}</span>
          <br />
          <span
            style="font-size: 18px; color:#FFF; cursor: pointer"
            class="font-weight-normal"
            @click="openFileDialog('tnc')"
            >{{ lang[getLanguage].MY_PROFILE_TANDC }}</span>
          <br />
          <span
            style="font-size: 18px; color:#FFF; cursor: pointer"
            class="font-weight-normal"
            @click="openContact()"
            >{{ lang[getLanguage].CONTACT_US }}</span>
          <br /> -->
          <!-- <span
            style="font-size: 18px; color:#FFF; cursor: pointer"
            class="font-weight-normal"
            @click.stop="gotoLink('https://mola.tv/accounts/profile?tab=subscriptionPackage')"
            >{{ lang[getLanguage].MY_PROFILE_SUBSCRIBE_NOW }}</span> -->
        </v-col>

        <v-col cols="12" md="3" class="mx-auto">
          <!-- <span
            style="font-size: 22px; color:#FFF;"
            class="font-weight-bold"
            >{{ lang[getLanguage].MOLA_FIGHT_CLUB }}</span>
          <br />
          <br />
          <span
            style="font-size: 18px; color:#FFF; cursor: pointer"
            class="font-weight-normal"
            @click="routerGo('/')"
            v-scroll-to="'#home'"
            >{{ lang[getLanguage].HOME }}</span>
          <br />
          <span
            style="font-size: 18px; color:#FFF; cursor: pointer"
            class="font-weight-normal"
            @click="routerGo('/schedule')"
            v-scroll-to="'#broadcastschedule'"
            >{{ lang[getLanguage].BROADCAST_SCHEDULE }}</span>
          <br />
          <span
            style="font-size: 18px; color:#FFF; cursor: pointer"
            class="font-weight-normal"
            @click="routerGo('/leaderboard')"
            v-scroll-to="'#leaderboard'"
            >{{ lang[getLanguage].LEADERBOARD }}</span>
          <br />
          <span
            style="font-size: 18px; color:#FFF; cursor: pointer"
            class="font-weight-normal"
            @click="routerGo('/news')"
            v-scroll-to="'#news'"
            >{{ lang[getLanguage].NEWS }}</span>
          <br />
          <span
            style="font-size: 18px; color:#FFF; cursor: pointer"
            class="font-weight-normal"
            @click="routerGo('/faq')"
            v-scroll-to="'#faq'"
            >{{ lang[getLanguage].FAQ }}</span>
          <br /> -->
        </v-col>

        <v-col cols="12" md="3" class="mx-auto d-flex justify-end">
          <v-layout class="justify-md-end align-md-end align-center justify-center" :column="drawer">
            <v-img style="cursor: pointer;"
                   max-width="140"
                   max-height="41"
                   src="/img/icons/google_play.png" 
                   @click="gotoLink('https://play.google.com/store/apps/details?id=tv.mola.app')">
            </v-img>
            <div class="mx-2"></div>
            <v-img style="cursor: pointer;"
                   max-width="140"
                   max-height="41"
                   src="/img/icons/app_store.png" 
                   @click="gotoLink('https://apps.apple.com/id/app/mola/id1473256917')">
            </v-img>
          </v-layout>
        </v-col>
        <!--<v-col cols="12" md="3">
          <span
            style="font-size: 22px; color:#FFF;"
            class="font-weight-bold"
            >{{ lang[getLanguage].SOCIAL }}</span>
          <br />
          <br />
              <v-icon class="mx-2" x-large color="white" style="cursor: pointer">mdi-twitter</v-icon>
              <v-icon class="mx-2" x-large color="white" style="cursor: pointer">mdi-instagram</v-icon>
              <v-icon class="mx-2" x-large color="white" style="cursor: pointer" @click="gotoLink('mailto:info@molafightclub.com')">mdi-email</v-icon>
             
        </v-col> -->

        <v-col cols="12" class="mt-4" >
          <v-row class="mt-md-8 mt-0 mx-auto">
            <v-col cols="12" class="d-flex justify-center" v-if="isMobileDevice">
              <span style="font-size: 16px; color:#FFF;" v-if="isMobileDevice">
                <v-btn
                  text 
                  style="color:#FFF;text-transform: none !important;"
                  @click="gotoLink('mailto:info@molafightclub.com')">
                <v-icon 
                  left
                  :color="dark ? 'white' : 'blue'"
                  >mdi-email</v-icon> info@molafightclub.com
                </v-btn>
              </span>
            </v-col>
            <v-col cols="12" class="d-flex justify-center" v-if="isMobileDevice">
              <span style="font-size: 16px; color:#FFF;">Copyright &copy; Mola, {{ year }}</span>
            </v-col>
            <span v-if="!isMobileDevice"  style="font-size: 16px; color:#FFF;">Copyright &copy; Mola, {{ year }}</span>
            <v-spacer v-if="!isMobileDevice"></v-spacer>
            <span style="font-size: 16px; color:#FFF;" v-if="!isMobileDevice">
              <v-btn
                text 
                style="color:#FFF;text-transform: none !important;"
                @click="gotoLink('mailto:info@molafightclub.com')">
              <v-icon 
                left
                :color="dark ? 'white' : 'blue'"
                >mdi-email</v-icon> info@molafightclub.com
              </v-btn>
            </span>
          </v-row>
        </v-col>
      </v-row>
    </v-footer>
    </div>

</template>
<script>
export default {
  name: 'appfooter',
  props: {
    isOpen: Boolean,
    isMobileDevice: Boolean,
    dark: Boolean,
    drawer: Boolean
  },
  data: () => ({
    links: [
        'Home',
        'Broadcast Schedule',
        'Leaderboard',
        'News',
        'Contact'
    ],
    year: 2024
  }),
  computed:{
    lang(){
      return this.$store.state.lang;
    },
    getLanguage(){
      return this.$store.state.current_language
    },
  },
  created() {
    this.year = this.getYear();
  },
  methods: {
    routerGo(route) {
      if (this.$router.currentRoute.path !== route) {
        this.$router.push(route);
      }
    },
    gotoLink(link) {
      if (this.isMobileDevice) {
        window.location.href = link
      } else {
        window.open(link, "_blank");
      }      
    },
    openContact(){
      console.log("test")
      this.$emit('contact', true);
    },
    openFileDialog(type){
      this.$emit('fileDialogClicked', type);
    },
    getYear(){
      return new Date().getFullYear();
    }
  },
};
</script>