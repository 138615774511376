<template>
  <v-container class="containerDiv mx-auto px-0" fluid>
    <v-row class="my-4 mx-auto px-auto">
      <v-col cols="12" md="12" :align="'center'">
        <v-img src="/img/home/predict_now.png" width="350" class="align-center">
          <h1 class="px-8" 
            style="width:100%; text-align: center; padding: 10px; text-transform: uppercase; word-break: break-word;">
            <v-typography v-if="!blankTitle && getLanguage === 'en'">{{title.en}}</v-typography>
            <v-typography v-if="!blankTitle && getLanguage === 'id'">{{title.id}}</v-typography>
            <v-typography v-if="!blankTitle && getLanguage === 'it'">{{title.it}}</v-typography>
          </h1>
        </v-img>
      </v-col>
    </v-row>
    
    <v-row class="mt-8 mx-auto mx-md-0" :style="`${isMobile ? '' : 'height: 350px'}`">
      <v-col :class="`${isMobile ? 'mobile-row' : 'left-row'} mx-md-auto px-md-auto mx-0 px-0`" cols="12" md="6">
        <h2 class="text-center white--text mb-8 mt-3" style="font-family:'Furore';">
          <v-typography style="font-family:'Furore' !important;" v-if="getLanguage === 'en'" class="title" color="#fecd38">
            {{ specialPrizeTitle.en.split(" ").slice(0, Math.ceil(specialPrizeTitle.en.split(" ").length/2)).join(" ") }}
            <span class="black--text">{{ specialPrizeTitle.en.split(" ").slice(Math.ceil(specialPrizeTitle.en.split(" ").length/2)).join(" ") }}</span>
          </v-typography>
          <v-typography style="font-family:'Furore' !important;" v-if="getLanguage === 'id'" class="title" color="#fecd38">
            {{ specialPrizeTitle.id.split(" ").slice(0, Math.ceil(specialPrizeTitle.id.split(" ").length/2)).join(" ") }}
            <span class="black--text">{{ specialPrizeTitle.id.split(" ").slice(Math.ceil(specialPrizeTitle.id.split(" ").length/2)).join(" ") }}</span>
          </v-typography>
          <v-typography style="font-family:'Furore' !important;" v-if="getLanguage === 'it'" class="title" color="#fecd38">
            {{ specialPrizeTitle.it.split(" ").slice(0, Math.ceil(specialPrizeTitle.it.split(" ").length/2)).join(" ") }}
            <span class="black--text">{{ specialPrizeTitle.it.split(" ").slice(Math.ceil(specialPrizeTitle.it.split(" ").length/2)).join(" ") }}</span>
          </v-typography>
        </h2>
        <v-row class="mx-0 px-0 mx-md-auto px-md-auto">
          <v-col cols="12" class="d-flex justify-center mx-0 px-0 mx-md-auto px-md-auto">
            <v-img
              :src="isMobile ? prizeCatalogBannerMobileUrl : prizeCatalogBannerDesktopUrl"
              alt="item image"
              class="image mx-0 mx-md-auto px-md-auto"
              contain
              style="cursor: pointer;"
              :max-width="isMobile ? '100%' : 500"
              @click="gotoLink(imageLink)"
            ></v-img>
          </v-col>
        </v-row>
      </v-col>
      <v-col :class="`${isMobile ? 'mobile-row' : 'right-row'} mx-md-auto px-md-auto mx-0 px-0 pb-0`" cols="12" md="6">
        <h2 class="text-center black--text mb-8 mt-3" style="font-family:'Furore';">
          <v-typography style="font-family:'Furore' !important;" v-if="getLanguage === 'en'" class="title" color="#fecd38">
            {{ prizeCatalogTitle.en.split(" ").slice(0, Math.ceil(prizeCatalogTitle.en.split(" ").length/2)).join(" ") }}
            <span class="white--text">{{ prizeCatalogTitle.en.split(" ").slice(Math.ceil(prizeCatalogTitle.en.split(" ").length/2)).join(" ") }}</span>
          </v-typography>
          <v-typography style="font-family:'Furore' !important;" v-if="getLanguage === 'id'" class="title" color="#fecd38">
            {{ prizeCatalogTitle.id.split(" ").slice(0, Math.ceil(prizeCatalogTitle.id.split(" ").length/2)).join(" ") }}
            <span class="white--text">{{ prizeCatalogTitle.id.split(" ").slice(Math.ceil(prizeCatalogTitle.id.split(" ").length/2)).join(" ") }}</span>
          </v-typography>
          <v-typography style="font-family:'Furore' !important;" v-if="getLanguage === 'it'" class="title" color="#fecd38">
            {{ prizeCatalogTitle.it.split(" ").slice(0, Math.ceil(prizeCatalogTitle.it.split(" ").length/2)).join(" ") }}
            <span class="white--text">{{ prizeCatalogTitle.it.split(" ").slice(Math.ceil(prizeCatalogTitle.it.split(" ").length/2)).join(" ") }}</span>
          </v-typography>
        </h2>
        <v-row class="mx-0 px-0 mx-md-auto px-md-auto">
          <v-col cols="12" class="d-flex justify-center mx-0 px-0 mx-md-auto px-md-auto">
            <v-img
              :src="isMobile ? specialPrizeBannerMobileUrl : specialPrizeBannerDesktopUrl"
              alt="item image"
              class="image mx-0 mx-md-auto"
              contain
              style="cursor: pointer;"
              :max-width="isMobile ? '100%' : 500"
              @click="gotoLink(imageLink)"
            ></v-img>
          </v-col>
        </v-row>
      </v-col>
    </v-row >
  </v-container>
</template>
<script>
import {db} from '@/main';
export default {
  props: {
    isMobileDevice: Boolean
  },
  data(){
    return {
      specialPrizeBannerDesktopUrl: '',
      specialPrizeBannerMobileUrl: '',
      prizeCatalogBannerDesktopUrl: '',
      prizeCatalogBannerMobileUrl: '',
      specialPrizeTitle: '',
      prizeCatalogTitle: '',
      imageLink: '',
      title: '',
      blankTitle: false,
    }
  },
  created(){
    this.getImageUrl()
  },
  computed: {
    getLanguage(){
      return this.$store.state.current_language
    },
    lang(){
      return this.$store.state.lang;
    },
    getUser() {
      return this.$store.state.user;
    },
    isUserLogin() {
      return this.user !== null;
    },
    isMobile(){
      return this.isMobileDevice || this.$vuetify.breakpoint.mdAndDown;
    }
  },
  methods: {
    async getImageUrl() {
      const snapshot = await db.collection('config').doc("reward_config").get();
      if(snapshot.exists){
        const obj = snapshot.data();
        this.specialPrizeBannerDesktopUrl = obj.specialPrizeBannerDesktop.url
        this.specialPrizeBannerMobileUrl = obj.specialPrizeBannerMobile.url
        this.prizeCatalogBannerDesktopUrl = obj.prizeCatalogBannerDesktop.url
        this.prizeCatalogBannerMobileUrl = obj.prizeCatalogBannerMobile.url
        this.specialPrizeTitle = obj.specialPrizeTitle
        this.prizeCatalogTitle = obj.prizeCatalogTitle
        this.imageLink = obj.imageUrlLink;
        this.title = obj.title;
        this.blankTitle = obj.blankTitle;
      }
    },
    gotoLink(link) {
      if (link === '') return;
      if (link.includes('catalog') && link.includes('moladigital.com')){
        this.openCatalog();
        return;
      }
      return window.open(link, "_self");
    },
    openCatalog () {
      console.log(this.isUserLogin);
      if (!this.isUserLogin) {
        window.open('https://catalog.moladigital.com/auth/bG9nb3V0/bm90bG9nZ2VkaW4=',"_self");
        return
      }
      let userObj = {
        email: this.getUser.email,
        name: this.getUser.name,
        souce: 'mfp-fight',
        deviceId: this.getUser.deviceId,
        mfp_fight_uid: this.getUser.uid,
        language: 'id'//this.getUser.language
      }
      // console.log(userObj)
      let objEncypt = this.$CryptoJS.AES.encrypt(JSON.stringify(userObj), "bamboozle").toString()
      objEncypt = objEncypt.replace(/\//g,'-')
      // console.log(objEncypt)

      var CATALOG_APP;
      if (process.env.VUE_APP_ENVIRONMENT === "production") {
        console.log("PRODUCTION ENVIRONMENT");
        CATALOG_APP = process.env.VUE_APP_CATALOG;
      }
      else if (process.env.VUE_APP_ENVIRONMENT === "staging") {
        console.log("TEST ENVIRONMENT");
        CATALOG_APP = process.env.VUE_APP_CATALOG_TEST;
      } 
      else{
        console.log("LOCAL ENVIRONMENT");
        CATALOG_APP = process.env.VUE_APP_CATALOG_LOCAL;
      }
      var token =
        Math.random().toString(36).substring(2, 12) +
        Math.random().toString(36).substring(2, 12) +
        Math.random().toString(36).substring(2, 12)
      var link = CATALOG_APP + 'auth/' + objEncypt + '/' + token
      console.log("New Link", link);
      window.open(link,"_self");
      /* objEncypt = objEncypt.replaceAll('-', '/')
      console.log(objEncypt)
      const decryptedText = this.$CryptoJS.AES.decrypt(objEncypt, "bamboozle").toString(this.$CryptoJS.enc.Utf8)
      console.log(JSON.parse(decryptedText)) */
    },
  }
}
</script>
<style>
@font-face {
  font-family: 'Furore';
  font-style: normal;
  font-weight: normal;
  src: local('Furore'), url('../../assets/Furore.woff') format('woff');
}

  /* .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 50px;
    height: 175px;
  } */
  /* li {
  background-color: #db0019;
  transform: skewX(-20deg);
  list-style-type: none;

} */
.left-row {
  clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);
  background-color: #C40721;
}
.right-row {
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
  background-color: #C40721;
}

.mobile-row {
  background-color: #C40721;
}


</style>