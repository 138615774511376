<template>
  <div id="home" :style="dark ? 'background-color: #000;' : 'background-color: #000;'">
    <h1 style="display:none">Welcome to Mola UFC Fight Predictor</h1>
    <h2 style="display:none">Predict UFC Fight Outcomes Now!</h2>
    <h3 style="display:none">How to Play UFC Fight Predictor</h3>

    <v-slide-y-transition mode="out-in">
      <v-layout column>
        <!-- ####################### SKELETON LOADERS ####################### -->
        <v-layout
          column
          align-center
          v-if="loading"
          :style="windowWidth < 770 ? 'margin-top:30px;margin-bottom:100px' : 'margin-top:150px;margin-bottom:100px'"
        >
          <p class="mt-2"></p>

          <v-progress-circular
            color="#db0019"
            indeterminate
          ></v-progress-circular>
        </v-layout>


        <!-- ############################################## COMPONENTS  ##############################################  -->
        <v-layout column v-show="!loading">
          <v-layout id="home" column>
            <v-card
              v-show="!isMobileDevice && !loading && isAllRendered"
              flat
              :style="dark ? 'background-color: #000;' : 'background-color: #000;height:100%'"
              style="border-radius:0px"
              :dark="dark"
              :width="'100%'"
              :min-height="windowHeight"
            >
            <v-carousel 
              v-model="carousel" 
              hide-delimiters
              :show-arrows="carouselItemsComp.filter(item => item.src !== '').length > 1"
              cycle
              :interval="carouselInterval"
              height="auto"
            >
              <v-carousel-item
                v-for="(item, i) in carouselItemsComp.filter(item => item.src !== '')"
                :key="i"
              >
              <!-- :lazy-src="homeBannerDesktop01" -->
              <v-img
                :src="item.src"
                :min-height="'100%'"
                :max-height="windowHeight"
                :max-width="windowWidth"
                @click="gotoLink(item.link)"
                :style="item.link === '' ? '' : 'cursor: pointer'"
                rel="preload"
                eager
              >
                <v-container style="height:100%">
                  <v-row class="mx-auto px-16" style="height:100%">
                    <v-col cols="12" class="d-flex flex-column justify-end px-8">
                      <div>
                        <div class="homeTitleNew" v-html="homeTitle[getLanguage]" v-if="!homeBlankTitle"></div>
                        <div
                          style="font-size:20px;color:#FFF"
                          class="font-weight-medium my-4 mb-6" v-html="homeTagline[getLanguage]"
                          v-if="!homeBlankTagline"
                          >
                        </div>
                        <!--<span class="ml-1">
                          <v-btn 
                            :dark="!dark" 
                            style="margin-left:-3px;height:40px;color:#000;" 
                            color="#FFF"
                            v-scroll-to="'#broadcastschedule'"
                            @click.stop=""
                          >
                          {{ lang[getLanguage].HOME_BUTTON }}<v-icon>mdi-arrow-right-thin</v-icon>
                          </v-btn>
                        </span> -->
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-img>
              </v-carousel-item>
            </v-carousel>
            <!--new version-->
              <!-- <v-img
                :src="homeBannerDesktop"
                :max-height="windowheight - 100"
                :max-width="windowWidth"
                @click="gotoLink(homeBannerDesktopLink01)"
                :style="homeBannerDesktopLink01 === '' ? '' : 'cursor: pointer'"
              >
                <v-container style="height:100%">
                  <v-row class="mx-auto px-16" style="height:100%">
                    <v-col cols="12" class="d-flex flex-column justify-end px-8">
                      <div>
                        <div class="homeTitleNew" v-html="homeTitle[getLanguage]" v-if="!homeBlankTitle"></div>
                        <div
                          style="font-size:20px;color:#FFF"
                          class="font-weight-medium my-4 mb-6" v-html="homeTagline[getLanguage]"
                          v-if="!homeBlankTagline"
                          >
                        </div>
                        <span class="ml-1">
                          <v-btn 
                            :dark="!dark" 
                            style="margin-left:-3px;height:40px;color:#000;" 
                            color="#FFF"
                            v-scroll-to="'#broadcastschedule'"
                            @click.stop=""
                          >
                          {{ lang[getLanguage].HOME_BUTTON }} <v-icon>mdi-arrow-right-thin</v-icon>
                          </v-btn>
                        </span>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-img> -->


              <v-row class="pt-8 mx-auto px-auto">
                <v-col cols="12" md="12" :align="'center'">
                  <v-img src="/img/home/predict_now.png" width="350" class="align-center" style="cursor: pointer;" v-scroll-to="'#broadcastschedule'">
                    <div class="text-h4 text-center black--text font-weight-bold">{{ lang[getLanguage].HOME_BUTTON_PREDICT }}</div>
                    <div class="text-h4 text-center black--text font-weight-bold">{{ lang[getLanguage].HOME_BUTTON_NOW }}</div>
                  </v-img>
                </v-col>
              </v-row>

              <v-toolbar
                height="70"
                color="#000"
              >
                <!--  <v-row>
                  <v-col cols="3" class="d-flex justify-center"><v-card :dark="!dark" class="pa-0 card-color"><v-card-title class="py-0">{{ getUser.recap_overall_rank }}</v-card-title><v-card-text class="py-0">Overall Rank</v-card-text></v-card></v-col>
                  <v-col cols="3" class="d-flex justify-center"><v-card :dark="!dark" class="pa-0 card-color"><v-card-title class="py-0">{{ getUser.recap_overall_point }}</v-card-title><v-card-text class="py-0">Overall Points</v-card-text></v-card></v-col>
                  <v-col cols="3" class="d-flex justify-center"><v-card :dark="!dark" class="pa-0 card-color"><v-card-title class="py-0">{{ getUser.recap_weekly_rank }}</v-card-title><v-card-text class="py-0">Weekly Rank</v-card-text></v-card></v-col>
                  <v-col cols="3" class="d-flex justify-center"><v-card :dark="!dark" class="pa-0 card-color"><v-card-title class="py-0">{{ getUser.recap_weekly_point}}</v-card-title><v-card-text class="py-0">Weekly Points</v-card-text></v-card></v-col>
                </v-row> -->

                <v-row v-if="getEventList.length > 0" class="mt-2">
                  <v-col cols="12" class="d-flex justify-center">
                      <v-btn  elevation="10"
                              style="color: #FFF;"
                              color="#db0019"
                              rounded
                              dark
                              @click="readBeforePredictingDialog = true"
                        >
                        <!-- <v-icon left>mdi-information-outline</v-icon> -->{{ lang[getLanguage].HOME_HOW_TO_PLAY }}
                      </v-btn>
                  </v-col>
                </v-row>

              </v-toolbar>

              <BroadcastSchedule 
                v-if="isAllRendered"
                id="broadcastschedule"
                :dark="dark" 
                :isMobileDevice="isMobileDevice"
                v-on:loginClicked="loginClicked"
                v-on:liveLeaderboardClicked="liveLeaderboardClicked"
                style="margin-top: 30px"
              >
              </BroadcastSchedule>

            </v-card>


            <v-card
              flat
              v-show="isMobileDevice && !loading && isAllRendered"
              style="border-radius:0px"
              :dark="dark"
            >        
              <!-- new -->
              <v-carousel 
                v-model="carouselMobile" 
                hide-delimiters
                :show-arrows="carouselItemsMobileComp.filter(item => item.src !== '').length > 1"
                :cycle="showCarousel"
                :interval="carouselInterval"
                height="100%"
              >
                <v-carousel-item
                  v-for="(item, j) in carouselItemsMobileComp.filter(item => item.src !== '')"
                  :key="j"
                  height="100%"
                >
                  <v-img
                    v-if="item.src !== ''"
                    :lazy-src="homeBannerMobile01"
                    :src="item.src"
                    height="100%"
                    @click="gotoLink(item.link ?? homeBannerMobileLink01)"
                    class="mobileBanner"
                    :style="homeBannerMobileLink01 === '' ? '' : 'cursor: pointer'"
                    eager
                  >
                    <v-container style="height:100%">
                      <v-row class="mx-auto px-0" style="height:100%">
                        <!--justify-start for text in the top-->
                        <!--justify-center for text in the middle-->
                        <!--justify-end for text in the bottom-->

                        <v-col cols="12" class="d-flex flex-column justify-end px-8">
                          <div>
                            <div :class="['id', 'it'].includes(getLanguage) ? 'homeTitleNew-id' : 'homeTitleNew'" v-html="homeTitle[getLanguage]" v-if="!homeBlankTitle"></div>
                            <div
                              style="font-size:20px;color:#FFF"
                              class="font-weight-medium my-4" v-html="homeTagline[getLanguage]"
                              v-if="!homeBlankTagline"
                              >
                            </div>
                            <!-- <span class="ml-1">
                              <v-btn 
                                :dark="!dark" 
                                style="margin-left:-3px;height:40px;color:#000;" 
                                color="#FFF"
                                v-scroll-to="'#broadcastschedule'"
                                @click.stop=""
                              >
                              {{ lang[getLanguage].HOME_BUTTON }} <v-icon>mdi-arrow-right-thin</v-icon>
                              </v-btn>
                            </span> -->
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-img>
              </v-carousel-item>
            </v-carousel>


              <v-row class="pt-8 mx-auto px-auto">
                <v-col cols="12" md="12" :align="'center'">
                  <v-img src="/img/home/predict_now.png" width="350" class="align-center" style="cursor: pointer;" v-scroll-to="'#broadcastschedule2'">
                    <div class="text-h4 text-center black--text font-weight-bold">{{ lang[getLanguage].HOME_BUTTON_PREDICT }}</div>
                    <div class="text-h4 text-center black--text font-weight-bold">{{ lang[getLanguage].HOME_BUTTON_NOW }}</div>
                  </v-img>
                </v-col>
              </v-row>

              <v-toolbar
                height="70"
                color="#000"
              >
                <!-- <v-row>
                  <v-col cols="6" md="3" class="d-flex justify-center"><v-card :dark="!dark" class="pa-0 card-color"><v-card-title class="py-0">{{ getUser.recap_overall_rank }}</v-card-title><v-card-text class="py-0">Overall Rank</v-card-text></v-card></v-col>
                  <v-col cols="6" md="3"  class="d-flex justify-center"><v-card :dark="!dark" class="pa-0 card-color"><v-card-title class="py-0">{{ getUser.recap_overall_point }}</v-card-title><v-card-text class="py-0">Overall Points</v-card-text></v-card></v-col>
                  <v-col cols="6" md="3" class="d-flex justify-center"><v-card :dark="!dark" class="pa-0 card-color"><v-card-title class="py-0">{{ getUser.recap_weekly_rank }}</v-card-title><v-card-text class="py-0">Weekly Rank</v-card-text></v-card></v-col>
                  <v-col cols="6" md="3" class="d-flex justify-center"><v-card :dark="!dark" class="pa-0 card-color"><v-card-title class="py-0">{{ getUser.recap_weekly_point}}</v-card-title><v-card-text class="py-0">Weekly Points</v-card-text></v-card></v-col>
                </v-row> -->

                <v-row v-if="getEventList.length > 0">
                  <v-col cols="12" class="d-flex justify-center">
                    <v-btn elevation="10"
                            color="#db0019"
                            dark
                            rounded
                            @click="readBeforePredictingDialog = true"
                      >
                      <!-- <v-icon left>mdi-information-outline</v-icon> -->{{ lang[getLanguage].HOME_HOW_TO_PLAY }}
                    </v-btn>
                  </v-col>
                </v-row>

              </v-toolbar>
              
              <BroadcastSchedule 
                v-if="isAllRendered"
                id="broadcastschedule2"
                :dark="dark" 
                :isMobileDevice="isMobileDevice"
                v-on:loginClicked="loginClicked"
                v-on:liveLeaderboardClicked="liveLeaderboardClicked"
                style="margin-top: 40px"
              >
              </BroadcastSchedule>

            </v-card>
            <Rewards :isMobileDevice="isMobileDevice" v-show="false"/>
            <PrizeSection :isMobileDevice="isMobileDevice" class="mb-md-16"></PrizeSection>
          </v-layout>


        </v-layout>

            <!-- ################### HOW TO PLAY ########################## -->
            <v-dialog
              v-model="readBeforePredictingDialog"
              max-width="750"
            >
              <v-card :dark="dark" style="border-radius: 3px;border: #f8df8e solid 5px;background-color: #404040;">

                <v-layout>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="readBeforePredictingDialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-layout>

                <v-card-item>
                  <v-card-title :class="isMobileDevice ? 'text-h6' : 'text-h5'" >
                    {{ lang[getLanguage].HOW_TO_PLAY }}?
                  </v-card-title>
                  <v-card-text style="text-align: justify;">
                    <div style="font-size: 1rem;" v-html="lang[getLanguage].HOW_TO_PLAY_TEXT"></div>
                  </v-card-text>
                  <v-spacer></v-spacer>
                </v-card-item>
                
                <v-card-title :class="isMobileDevice ? 'text-h6' : 'text-h5'" >
                  {{ lang[getLanguage].HOME_READ_BEFORE_PREDICTING }}
                </v-card-title>
                
                  <v-card-text style="text-align: justify;">
                    <div style="font-size: 1rem;color:#FFF" v-html="lang[getLanguage].HOME_READ_BEFORE_PREDICTING_TEXT"></div>
                  </v-card-text>
                  <v-spacer></v-spacer>


                  <v-card-title class="text-h5" >
                    {{ lang[getLanguage].BROADCAST_POINTS_ALLOCATION }}
                  </v-card-title>

                  <v-card-text v-if="getEventList.length > 0" style="color:#FFF">
                    <div style="font-size: 1rem;">{{ lang[getLanguage].BROADCAST_CORRECT_OUTCOME }} {{ getEventList[0].points_outcome }}</div>
                    <div style="font-size: 1rem;">{{ lang[getLanguage].BROADCAST_CORRECT_ROUND }} {{ getEventList[0].points_rounds }} {{ 'x'+getEventList[0].points_outcome }} {{ lang[getLanguage].BROADCAST_BONUS }}</div>
                    <div style="font-size: 1rem;">{{lang[getLanguage].BROADCAST_CORRECT_METHOD}} {{ getEventList[0].points_method }} {{ 'x'+getEventList[0].points_outcome }} {{ lang[getLanguage].BROADCAST_BONUS }}</div>
    
                    <div style="font-size: 1rem;" class="mb-2">{{lang[getLanguage].BROADCAST_BONUS_ALL}} + {{ getEventList[0].points_bonus }} {{ lang[getLanguage].BROADCAST_BONUS_POINTS }}</div>
                      
                    <div style="font-size: 1rem;">{{ lang[getLanguage].BROADCAST_MULTIPLIER_MAIN }} {{ getEventList[0].points_multi_main_card }}</div>
                    <div style="font-size: 1rem;">{{ lang[getLanguage].BROADCAST_MULTIPLIER_PRELIMS }}{{ getEventList[0].points_multi_prelims }}</div>
                    <div style="font-size: 1rem;">{{ lang[getLanguage].BROADCAST_MULTIPLIER_FEATURED }} {{ getEventList[0].points_multi_featured }}</div>
                  </v-card-text>
                  <v-card-text v-else style="color:#FFF">
                    {{ lang[getLanguage].HOW_TO_PLAY_NO_RECORD }}
                  </v-card-text>
                  

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="#db0019"
                      dark
                      @click="readBeforePredictingDialog = false"
                    >
                      {{ lang[getLanguage].CLOSE }}
                    </v-btn>
                  </v-card-actions>
              </v-card>

              <!-- <v-card :dark="!dark">
              <v-card-title class="text-h5" >
                {{ lang[getLanguage].BROADCAST_POINTS_ALLOCATION }}
              </v-card-title>
              
              <v-card-text>
                <div style="font-size: 1rem;">{{ lang[getLanguage].BROADCAST_CORRECT_OUTCOME }} {{ selectedEvent.points_outcome }}</div>
                <div style="font-size: 1rem;">{{ lang[getLanguage].BROADCAST_CORRECT_ROUND }} {{ selectedEvent.points_rounds }} {{ 'x'+selectedEvent.points_outcome }} {{ lang[getLanguage].BROADCAST_BONUS }}</div>
                <div style="font-size: 1rem;">{{lang[getLanguage].BROADCAST_CORRECT_METHOD}} {{ selectedEvent.points_method }} {{ 'x'+selectedEvent.points_outcome }} {{ lang[getLanguage].BROADCAST_BONUS }}</div>

                <div style="font-size: 1rem;" class="mb-2">{{lang[getLanguage].BROADCAST_BONUS_ALL}} + {{ selectedEvent.points_bonus }} {{ lang[getLanguage].BROADCAST_BONUS_POINTS }}</div>
                  
                <div style="font-size: 1rem;">{{ lang[getLanguage].BROADCAST_MULTIPLIER_MAIN }} {{ selectedEvent.points_multi_main_card }}</div>
                <div style="font-size: 1rem;">{{ lang[getLanguage].BROADCAST_MULTIPLIER_PRELIMS }}{{ selectedEvent.points_multi_prelims }}</div>
                <div style="font-size: 1rem;">{{ lang[getLanguage].BROADCAST_MULTIPLIER_FEATURED }} {{ selectedEvent.points_multi_featured }}</div>
              </v-card-text>
              
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="pointsAllocationDialog = false"
                >
                  {{ lang[getLanguage].CLOSE }}
                </v-btn>
              </v-card-actions>
            </v-card> -->
            </v-dialog>

      </v-layout>
    </v-slide-y-transition>
    
  </div>
</template>

<script>
// @ is an alias to /src
import BroadcastSchedule from './users/BroadcastSchedule.vue';
import PrizeSection from '../components/prize_catalog/PrizeSection.vue'
import Rewards from '../components/shared/Rewards.vue';
export default {
  name: 'Home',
  props: {
    isMobileDevice: Boolean,
    dark: Boolean,
    windowWidth: Number,
    windowHeight: Number
  },
  metaInfo() {
    return {
      title: this.metaTitle,
      meta: [
        { name: 'description', content: this.metaDescription },
        { property: 'og:title', content: this.metaTitle },
        // { property: 'og:og:image', content: this.metaImg },
        // { property: 'og:og:url', content: this.metaUrl },
        { property: 'og:site_name', content: 'Mola - Fight Predictor' },
        {property: 'og:type', content: 'website' },
        {name: 'robots', content: 'index,follow' }
      ]
    }
  },
  data: () => ({
    metaTitle: 'Mola - Fight Predictor - Predict UFC Fight and Win Prizes',
    metaDescription: 'Welcome to Mola Fight Club, your ultimate hub for UFC news and fight predictor. Earn points, compete in our leaderboard, and win exclusive prizes!',
    loading: false,
    readBeforePredictingDialog: false,
    carousel: 0,
    carouselMobile: 0,
    carouselItems: [],
    carouselItemsMobile: [],
    selectedEvent: '',
    showCarousel: true,
    isAllRendered: false,
    items: [{ url: '/img/icons/ps5.jpeg', desc: 'PS5'},
            { url: '/img/icons/ufc_gloves.jpeg', desc: 'OFFICIAL UFC GLOVES'},
            { url: '/img/icons/ufc_shirt.jpeg', desc: 'OFFICIAL UFC SHIRT'},
            { url: '/img/icons/ps5.jpeg', desc: 'PS5'},
           ]
  }),
  components: {
    BroadcastSchedule,
    PrizeSection,
    Rewards
  },
  computed: {
    getUser() {
      return this.$store.state.user;
    },
    user() {
      return this.$store.getters.user;
    },
    isUserLogin() {
      return this.user !== null;
    },
    getEventList () {
      return this.$store.state.fb.eventList
    },
    lang(){
      return this.$store.state.lang;
    },
    getLanguage(){
      return this.$store.state.current_language
    },
    // Carousel 01
    carouselInterval () {
      return this.isAllRendered ? this.$store.state.carouselInterval : 250;
    },
    homeBannerDesktop01(){
      return this.$store.state.homeBannerDesktop01;
    },
    homeBannerDesktopLink01 () {
      return this.$store.state.homeBannerDesktopLink01;
    },
    homeBannerMobile01(){
      return this.$store.state.homeBannerMobile01;
    },
    homeBannerMobileLink01 () {
      return this.$store.state.homeBannerMobileLink01;
    },
    // Carousel 02
    homeBannerDesktop02(){
      return this.$store.state.homeBannerDesktop02;
    },
    homeBannerDesktopLink02 () {
      return this.$store.state.homeBannerDesktopLink02;
    },
    homeBannerMobile02(){
      return this.$store.state.homeBannerMobile02;
    },
    homeBannerMobileLink02 () {
      return this.$store.state.homeBannerMobileLink02;
    },
    // Carousel 03
    homeBannerDesktop03(){
      return this.$store.state.homeBannerDesktop03;
    },
    homeBannerDesktopLink03 () {
      return this.$store.state.homeBannerDesktopLink03;
    },
    homeBannerMobile03(){
      return this.$store.state.homeBannerMobile03;
    },
    homeBannerMobileLink03 () {
      return this.$store.state.homeBannerMobileLink03;
    },
    carouselItemsComp () {
      return [
        {
          src: this.homeBannerDesktop01,
          link: this.homeBannerDesktopLink01
        },
        {
          src: this.homeBannerDesktop02,
          link: this.homeBannerDesktopLink02
        },
        {
          src: this.homeBannerDesktop03,
          link: this.homeBannerDesktopLink03
        }
      ]
    },
    carouselItemsMobileComp () {
      return [
        {
          src: this.homeBannerMobile01,
          link: this.homeBannerMobileLink01
        },
        {
          src: this.homeBannerMobile02,
          link: this.homeBannerMobileLink02
        },
        {
          src: this.homeBannerMobile03,
          link: this.homeBannerMobileLink03
        }
      ]
    },
    // END
    homeTitle(){
      console.log(this.$store.state.homeTitle)
      return this.$store.state.homeTitle;
    },
    homeTagline(){
      console.log(this.$store.state.homeTagline)
      return this.$store.state.homeTagline;
    },
    homeBlankTitle(){
      return this.$store.state.blankTitle;
    },
    homeBlankTagline(){
      return this.$store.state.blankTagline;
    },
    role() {
      return this.$store.getters.role;
    },
    userRole() {
      switch (this.role) {
        case 1:
          return 'ADMIN';
        case 2:
          return 'ADMIN-NEWS';
        case 3:
          return 'ADMIN-LEADERBOARD';
        case 4:
          return 'ADMIN-PREDICTION';
        case 5:
          return 'ADMIN-MIXED';
        case 6:
          return 'ADMIN-SUBSCRIBER';
        case 10:
          return 'USER';
        default:
          return 'PUBLIC';
      }
    },
    filteredEventList () {
      console.log("filter", this.combine)
      if (this.combine === 'tabs' || this.combine === 'all') {
        return this.getEventList
      } else {
        return this.getEventList.filter(item => item.type === this.eventType)
      }
    },
  },
  watch: {
    carousel(newVal) {
      console.log('caruosel', newVal)
      if(newVal == (this.carouselItemsComp.filter(item => item.src !== '').length - 1)){
        setTimeout(() => {
          this.isAllRendered = true;
        }, 500);
      }
    },
    carouselMobile(newVal) {
      console.log('caruosel', newVal)
      if(newVal == 2){
        setTimeout(() => {
          this.isAllRendered = true;
        }, 500);
      }
    }
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  created() {
    // this.currentUser = firebase.auth().currentUser;
    // this.init();
    // const snapshot = await db.collection('config').doc("homepage_config").get();
    // if(snapshot.exists){
    //   const obj = snapshot.data();
    //   this.homeBannerDesktop = obj.homeBannerDesktop.url;
    //   this.homeBannerMobile = obj.homeBannerMobile.url;
    //   this.liveUrl = obj.liveButtonLink;
    // }
    // else{
    //   this.homeBannerDesktop = '/img/home/home_bg_new_03.webp';
    //   this.homeBannerMobile = '/img/home/home_bg_mobile_03.webp';
    //   this.liveUrl = 'https://molafightclub.com/schedule';
    // }
    window.addEventListener('scroll', this.handleScroll);
    this.loading = true
    if(this.carouselItemsComp.filter(item => item.src !== '').length === 1){
      this.isAllRendered = true;
    }
    this.init ()
    
  },
  methods: {
    handleScroll (){
      // console.log('window.scrollY')
      // console.log(Math.floor(window.scrollY))
      // console.log('this.windowHeight')
      // console.log(Math.floor(this.windowHeight))

      if (!this.showCarousel && Math.floor(window.scrollY) > 0) return

      if (Math.floor(window.scrollY) > this.windowHeight - 100 && this.isMobileDevice) {
        this.showCarousel = false
      } else if (Math.floor(window.scrollY) === 0) {
        this.showCarousel = true
      }
    },
    init () {
      setTimeout(() => {
        this.loading = false
      }, 4000);
    },
    loginClicked () {
      this.$emit("loginClicked")
    },
    gotoLink(link) {
      console.log("Link", link, link.includes('catalog'), link.includes('moladigital.com'))
      if (link === '') return;
      if (link.includes('catalog') && link.includes('moladigital.com')){
        this.openCatalog();
        return;
      }
      window.open(link, "_blank");
    },
    openCatalog () {
      console.log(this.isUserLogin);
      if (!this.isUserLogin) {
        window.open('https://catalog.moladigital.com/auth/bG9nb3V0/bm90bG9nZ2VkaW4=',"_self");
        return
      }
      let userObj = {
        email: this.getUser.email,
        name: this.getUser.name,
        souce: 'mfp-fight',
        deviceId: this.getUser.deviceId,
        mfp_fight_uid: this.getUser.uid,
        language: 'id'//this.getUser.language
      }
      // console.log(userObj)
      let objEncypt = this.$CryptoJS.AES.encrypt(JSON.stringify(userObj), "bamboozle").toString()
      objEncypt = objEncypt.replace(/\//g,'-')
      // console.log(objEncypt)

      var CATALOG_APP;
      if (process.env.VUE_APP_ENVIRONMENT === "production") {
        console.log("PRODUCTION ENVIRONMENT");
        CATALOG_APP = process.env.VUE_APP_CATALOG;
      }
      else if (process.env.VUE_APP_ENVIRONMENT === "staging") {
        console.log("TEST ENVIRONMENT");
        CATALOG_APP = process.env.VUE_APP_CATALOG_TEST;
      } 
      else{
        console.log("LOCAL ENVIRONMENT");
        CATALOG_APP = process.env.VUE_APP_CATALOG_LOCAL;
      }
      var token =
        Math.random().toString(36).substring(2, 12) +
        Math.random().toString(36).substring(2, 12) +
        Math.random().toString(36).substring(2, 12)
      var link = CATALOG_APP + 'auth/' + objEncypt + '/' + token
      console.log("New Link", link);
      window.open(link,"_self");
      /* objEncypt = objEncypt.replaceAll('-', '/')
      console.log(objEncypt)
      const decryptedText = this.$CryptoJS.AES.decrypt(objEncypt, "bamboozle").toString(this.$CryptoJS.enc.Utf8)
      console.log(JSON.parse(decryptedText)) */
    },
    liveLeaderboardClicked () {
      // TO DO
      //this.$store.commit('setLiveLeaderbordEventId', this.getEventList[1].doc_id)
      this.$emit("liveLeaderboardClicked")
    },
    showLiveEventButton () {
      // console.log('########## showLiveEventButton ###############')
      // console.log(this.getEventList.length)
      if (this.getEventList.length === 0) return false
      if (this.getEventList[0].fights.length === 0) return false

      let event = this.getEventList[0]
      let today = new Date().getTime()
      let firstFightStarted = event.fights[event.fights.length -1].start - (1000 * 60 * 60) // 1 hrs before the game
      // console.log('firstFightStarted')
      // console.log(firstFightStarted)
      let lastFightEnded = event.fights[0].start + (1000 * 60 * 60 * 4) // 4 hrs after the game
      if (today > firstFightStarted && today < lastFightEnded && event.type === 'UFC') {
        return true
      }
      return false
    },
    routerGo(route) {
        /* if (this.isMobileDevice) {
          console.log("this.drawer = false");
          setTimeout(() => {
            this.drawer = false;
          }, 500);
        } */
        // console.log(this.$router.currentRoute.path)
        // console.log(route)
        this.drawer = false;
        if (this.$router.currentRoute.path !== route) {
          this.$router.push(route);
        }
      },
  }
}
</script>

<style>
  .v-image.mobileBanner.v-responsive.theme--dark .v-responsive__sizer {
    padding-bottom: 170% !important;
  }

  /* .homeTitleNew {
    font-size: 50px;
    font-weight: 600;
    line-height: 60px;
    color: #FFF;
  }

  .homeTitleNew-id {
    font-size: 32px;
    font-weight: 600;
    line-height: 36px;
    color: #FFF;
  }

.card-color{
   background-color: #07207ba9 !important;
   border: 2px solid rgba(4, 31, 111, 0.488) !important;
} */
</style>
