<template>
    <v-row justify="center" class="mx-auto">
      <v-dialog
        v-model="dialog"
        :width="!isMobileDevice ? '525px' : ''"
        :fullscreen="isMobileDevice"
        persistent
        style="overflow-x: unset; overflow-y: hidden;"
      >
        <v-card class="" :dark="true" v-if="getUser.mola_subscription === 1"
          style="overflow-x: hidden; overflow-y: hidden; border-radius: 3px;border: #cdb15f solid 5px;background-image: linear-gradient( rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.9) ), url('img/home/cage_bg_vertical.webp');background-size: cover">
          <v-layout>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDuelDialog()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-layout>

          <!-- <v-row :justify="'center'">
            <v-img
              src="/img/logos/superlive_x_mola_horizontal.webp"
              style="max-height: 45px; max-width: 237px;"
            ></v-img>
          </v-row> -->

          <v-card-title
            v-if="!isMobileDevice"
            class="justify-center text-yellow-mola"
          >
            <span :class="`font-weight-bold text-center mt-4`" 
                   style="font-family:'Furore';word-break: break-word;font-size: 3.5rem;line-height: 3.5rem">{{ currentTitle }}</span>
          </v-card-title>
          <v-card-title
            v-if="isMobileDevice"
            class="justify-center text-yellow-mola"
          >
            <span :class="`font-weight-bold text-center mt-4`" 
                   style="font-family:'Furore';word-break: break-word;font-size: 2.5rem;line-height: 2.5rem">{{ currentTitle }}</span>
          </v-card-title>

          <v-window v-model="step">
            <v-window-item :value="1">
              <v-card-text>
                <div class="my-2">
                    <div :class="getLanguage === 'id' ? 'text-center px-16 grey--text' : 'text-center px-16 grey--text text-uppercase'" style="font-size: normal; line-height: normal;" v-html="lang[getLanguage].DUEL_PROVE">
                    </div>
                    <!-- <div class="text-center">
                        <v-btn text color="#cdb15f" small style="text-transform: none !important;">
                            {{ lang[getLanguage].HOW_IT_WORKS }}
                        </v-btn>
                    </div> -->
                </div>
                <div class="text-h5 text-yellow-mola text-center mt-8 font-weight-bold">{{ lang[getLanguage].WHOS_YOUR_OPPONENT }}</div>
                
                <v-autocomplete v-if="!isUserAlreadyInDuel && !isActive"
                  light
                  :label="lang[getLanguage].SEARCH_OPPONENT_2"
                  hide-details
                  solo
                  class="my-4 rounded-xl"
                  v-model="username"
                  :loading="loadingAutoComplete"
                  :search-input.sync="search"
                  :items="userItems"
                  required
                  :no-data-text="lang[getLanguage].SEARCH_OPPONENT_2"
                  item-text="name"
                  item-value="uid"
                  append-icon="mdi-magnify"
                  return-object
                  chips
                  deletable-chips
                  :menu-props="{closeOnContentClick:true}"
                >
                <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click:close="remove()"
                    >
                      <v-avatar left>
                        <v-img :src="data.item.avatar === '' ? tempImage : data.item.avatar"></v-img>
                      </v-avatar>
                      {{ data.item.name }}
                    </v-chip>
                  </template>

                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content v-text="data.item"></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-avatar>
                        <img :src="data.item.avatar === '' ? tempImage : data.item.avatar">
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>

                </v-autocomplete>
                <v-text-field v-if="isActive" light
                  hide-details
                  solo
                  readonly
                  class="my-4 rounded-xl" append-icon="mdi-magnify">
                  <template v-slot:prepend-inner>
                    <v-chip class="ma-1" 
                      close
                      @click:close="removeRandom()">
                      <v-avatar left>
                        <v-img :src="randomName.avatar === '' ? tempImage : randomName.avatar"></v-img>
                      </v-avatar>
                      {{ randomName.name }}
                    </v-chip>
                  </template>
                </v-text-field>
                <!-- <div class="random row my-2" align="center" v-if="isActive">
                  <v-text-field v-model="randomName" light
                  :label="lang[getLanguage].SEARCH_OPPONENT"
                  hide-details
                  solo
                  class="my-4 rounded-xl" append-icon="mdi-magnify"></v-text-field>
                  <div
                      align="center"
                      v-for="(item, index) in randoms"
                      v-bind:key="index"
                      :item-index="index"
                      v-bind:class="{
                      'in' : index != currentIndex && index != currentIndex - 1,
                      'out': index == currentIndex - 1,
                      'current': index == currentIndex,
                      'random2': isActive,
                      'col-md-12': isActive
                  }"> 
                    <v-chip
                      pill
                      v-on="on"
                    >
                      <v-avatar left>
                        <v-img :src="item.avatar" v-if="randoms[currentIndex].avatar !== ''"></v-img>
                        <v-img src="/img/default.jpg" v-else></v-img>
                      </v-avatar>
                      {{ randoms[currentIndex].name }}
                    </v-chip>
                  </div>
                </div> -->
                <div v-if="isUserAlreadyInDuel" class="text-center ma-4" style="color: #2196F3;font-weight: bold;">
                  <v-icon color="blue">mdi-information-box-outline</v-icon>
                  {{ duelInfo.status === 1 ? lang[getLanguage].YOU_ALREADY_DUEL : 'Undangan duel Anda dengan' }} 
                  {{ duelInfo.opponent_username === getUser.name ? duelInfo.user_name : duelInfo.opponent_username }}
                  {{ duelInfo.status === 0 ? 'masih tertunda': '' }} 
                </div>
                <div class="text-center ma-4" v-if="isInvited" style="color: #EF9A9A;">
                  {{ this.message }}
                </div>

                <div class="text-yellow-mola text-center"><v-icon color="#cdb15f" class="mr-1" small>mdi-calendar-star</v-icon>{{ getEventList[0].name }}</div>
              </v-card-text>
            </v-window-item>
  
            <v-window-item :value="2">
              <div class="pa-4 text-center">
                <h3 class="text-h6 font-weight-light mb-2">
                  {{ lang[getLanguage].DUEL_CONFIRM }}
                </h3>
                <span class="text-caption grey--text"
                  >{{ lang[getLanguage].DUEL_CAPTION }}</span
                >
              </div>
            </v-window-item>
          </v-window>
  
          <v-divider></v-divider>
          <!-- {{ username.length === 0 || username?.mola_subscription === 1 }} -->
          <v-card-actions class="my-0 flex-column" v-if="isUserAlreadyInDuel">
            <v-btn @click="confirmationDialog = true" style="color: #fff"
              class="my-2 mx-0"
              v-show="step === 1"
              :disabled="duelInfo.status === 1"
              color="red"
              block
              large
              depressed>Batalkan Undangan</v-btn>
          </v-card-actions>
          <v-card-actions class="my-0 flex-column" v-else-if="(username.length === 0 || username?.mola_subscription === 1) && !isUserAlreadyInDuel">
            <v-btn
              style="color: #fff"
              class="my-2 mx-0"
              :disabled="isUserAlreadyInDuel || isRandomLoading"
              v-show="step === 1"
              color="red"
              block
              large
              depressed
              @click="randomize()"
            >
              <span v-if="!isRandomLoading">
                Pilih Acak Lawan
              </span>
              <span v-else>
                <v-progress-circular
                 indeterminate
                 color="#C40621"
               ></v-progress-circular>
              </span>
            </v-btn>
            <!-- || [null, undefined, ''].includes(selectedName) -->
            <v-btn
              style="color: #fff"
              :disabled="([null, undefined, ''].includes(username) || username.length === 0) 
                && ([null, undefined, ''].includes(randomName) || isRandom)"
              class="my-2 mx-0"
              v-show="step === 1"
              color="#C40621"
              block
              large
              depressed
              @click="sendDuelInvitation()"
            >
              <span v-if="!isLoading">
                {{ lang[getLanguage].SEND_DUEL_INVITATION }}
              </span>
              <span v-else>
                <v-progress-circular
                 indeterminate
                 color="#C40621"
               ></v-progress-circular>
              </span>
            </v-btn>

            <v-btn
              style="color: #fff"
              class="my-2"
              v-show="step === 2"
              color="#C40621"
              block
              large
              depressed
              @click="closeDuelDialog"
            >
              <span v-if="!isLoading">
                {{ lang[getLanguage].CLOSE }}
              </span>
              <span v-else>
                <v-progress-circular
                 indeterminate
                 color="#C40621"
               ></v-progress-circular>
              </span>
            </v-btn>
          </v-card-actions>
          <v-card-actions v-else class="d-flex flex-column">
            <v-card-text class="text-caption text-center" style="font-size: 24pt;">
              Pemain ini bukan pelanggan Mola, Anda tidak dapat berduel dengan mereka. Untuk berduel dengan pemain tersebut, mereka harus menjadi pelanggan Mola.
              Undang mereka untuk menjadi pelanggan Mola dengan salin text di bawah ini.
            </v-card-text>
            <v-card light style="border-radius: 25px;background-color: #FFF;" width="100%" class="text-caption ma-3 pa-4" v-html="lang[getLanguage].INVITE_TO_MOLA">
              
            </v-card>
            <v-btn style="color: #fff"
              class="my-2 mx-0"
              color="#C40621"
              block
              large
              v-clipboard="lang[getLanguage].INVITE_TO_MOLA_PLAIN"
              @success="handleSuccess"
              @error="handleError" 
              depressed>
              {{ lang[getLanguage].COPY_TO_CLIPBOARD }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-else dark style="overflow-x: hidden; overflow-y: hidden; border-radius: 3px;border: #cdb15f solid 5px;background-image: linear-gradient( rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.9) ), url('img/home/cage_bg_vertical.webp');background-size: cover">
          <v-layout>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDuelDialog()" color="white">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-layout>
          <v-card-title
            v-if="!isMobileDevice"
            class="justify-center text-yellow-mola"
          >
            <span :class="`font-weight-bold text-center mt-4`" 
                   style="font-family:'Furore';word-break: break-word;font-size: 3.5rem;line-height: 3.5rem">{{ currentTitle }}</span>
          </v-card-title>
          <v-card-title
            v-if="isMobileDevice"
            class="justify-center text-yellow-mola"
          >
            <span :class="`font-weight-bold text-center mt-4`" 
                   style="font-family:'Furore';word-break: break-word;font-size: 2.5rem;line-height: 2.5rem">{{ currentTitle }}</span>
          </v-card-title>
          <v-card-text class="my-8 text-center text-h6">
            {{ lang[getLanguage].SHOULD_BE_MOLA_SUBS }}
          </v-card-text>
          <v-card-actions>
            <v-btn 
              @click="gotoLink('https://mola.tv/')"
              style="color: #fff"
              class="my-2"
              color="#C40621"
              block
              large
              depressed>
              {{ lang[getLanguage].SUBSCRIBE_HERE }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="confirmationDialog"
        persistent width="500">
        <v-card dark>
          <v-card-title class="text-h5">
            Konfimasi Pembatalan Duel
          </v-card-title>

          <v-card-text class="my-8">
            Apakah anda yakin membatalkan duel dengan {{ duelInfo.opponent_username === getUser.name ? duelInfo.user_name : duelInfo.opponent_username }}?
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue"
              text
              :disabled="isLoadingCancel"
              @click="confirmationDialog = false"
            >
              Tutup
            </v-btn>
            <v-btn
              color="red"
              text
              :disabled="isLoadingCancel"
              @click="cancelDuel"
            >
              <span v-if="!isLoadingCancel">
                Ya, Batalkan Duel
              </span>
              <span v-else>
                <v-progress-circular
                 indeterminate
                 color="#C40621"
               ></v-progress-circular>
              </span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar :timeout="timeout"
        v-model="snackbar"
      >
        {{ message }}
  
        <template v-slot:action="{ attrs }">
          <v-btn
            color="red"
            text
            v-bind="attrs"
            @click="closeSnackbar()"
          >
            {{ lang[getLanguage].CLOSE }}
          </v-btn>
        </template>
      </v-snackbar>
    </v-row>
  </template>
  <script>
//   import firebase from 'firebase';
  import { db } from '../../main';
  import { FireSQL } from 'firesql';
  import MY_ACCOUNT_TEMP_IMAGE from '../../const/image/myAccount.js';
  export default {
    props: {
      isOpen: Boolean,
      eventId: String,
      isMobileDevice: Boolean,
    },
    data(){ return {
      step: 1,
      isLoadingCancel: false,
      username: [],
      isLoading: false,
      snackbar: false,
      message: '',
      timeout: 4000,
      search: null,
      loadingAutoComplete: false,
      entries: [],
      tempImage: MY_ACCOUNT_TEMP_IMAGE,
      isUserAlreadyInDuel: false,
      confirmationDialog: false,
      duelInfo: {},
      isInvited: false,
      whitelistMolaSubs: [-1, 0, 1, 2, 3, 4, 5],
      isActive: false,
      randoms: [],
      numb: 0,
      isRandom: false,
      isButtonActive: false,
      isRandomLoading: false,
      isInit: false,
      currentIndex: 0,
      stopIndex: 0,
      count: 0,
      maximumCount: 120,
      //Minimum count, more number more count
      minimumCount: 30,
      selectedName: null,
      randomName: null,
      interval: setInterval(this.getRandom.bind(this), 120),
      duelInfoObs: null,
      }
    },
    created() {
      this.step = 1;
      this.isActive = false;
      this.isRandom = false;
      this.randomName = null;
      this.isRandomLoading = false;
      this.isButtonActive = true;
      this.interval;
    },
    computed: {
      currentTitle() {
        switch (this.step) {
          case 1:
            return (this.lang[this.getLanguage].DUEL_TITLE ?? 'DUEL A FRIEND');
          // case 2:
          //   return 'Loading....';
          case 2:
            return (this.lang[this.getLanguage].INVITATION_SENT ?? 'INVITATION SENT');
          default:
            return '';
        }
      },
      getEventList () {
        return this.$store.state.fb.eventList
      },
      dialog: {
        get() {
          return this.isOpen;
        },
        set(newValue) {
          this.$emit('change', newValue);
        },
      },
      user() {
        return this.$store.getters.user;
      },
      lang(){
        return this.$store.state.lang;
      },
      getLanguage(){
        return this.$store.state.current_language
      },
      userItems(){
        return this.entries;
      },
      getUser() {
        return this.$store.state.user;
      },
    },
    // mounted() {
    //   this.localStorageHandler();
    // },
    watch: {
      async isOpen () {
        if (this.isOpen) {
          const isUserAlreadyInDuel = await this.checkIsDuelAlreadyExists()
          console.log(isUserAlreadyInDuel)
          this.isUserAlreadyInDuel = isUserAlreadyInDuel
        }
        // console.log(this.isOpen)
      },
      search (val) {
        // Items have already been loaded
        // this.entries = []
        if (val === null) {
          // this.entries = this.username
          return
        }
        if (val.length < 2) return
        if (this.userItems.length > 0) this.entries = [];
        
        this.loadingAutoComplete = true

        let val2 = this.camelize(val)
        // console.log(val, val2);
        const fireSQL = new FireSQL(db);
        // let query = "SELECT uid, mola_subscription, name, full_name, avatar FROM users WHERE name LIKE '" + val.toLowerCase() + "%' OR name LIKE '" + val2 + "%'" // AND mola_subscription = 1
        let query = `
          SELECT uid, mola_subscription, name, full_name, avatar, email
          FROM users 
          WHERE name LIKE '${val.toLowerCase()}%' OR name LIKE '${val2}%'
          OR name LIKE '${val.toUpperCase()}%'
          ` // AND mola_subscription = 1
        
        fireSQL.query(query).then(documents => {
          documents.forEach(doc => {
            /* Do something with the document */
            console.log(doc)
            if (
              doc.name !== this.getUser.name 
              && doc.email.split('@')[1] !== 'mola.tv' 
              // && doc.mola_subscription === 1
            ) {
              this.entries.push(doc);
              // console.log(this.entries);
            }
          });
        })
        .catch(err => {
            console.log(err)
        })
        .finally(() => (this.loadingAutoComplete = false))

        /*db.collection('users').where('name', 'in', [val, val2]).get()
          .then(res => {
              console.log(res);
              res.forEach(doc => {
                if (doc.data().name !== this.getUser.name) {
                  this.entries.push(doc.data());
                }
              })
          })
          .catch(err => {
              console.log(err)
          })
          .finally(() => (this.loadingAutoComplete = false)) */
      },
    },
    beforeDestroy() {
      this.duelInfoObs();
    },
    methods: {
      remove () {
        this.username = []
      },
      removeRandom () {
        this.randomName = null;
        this.username = [];
        this.isActive = false;
      },
      camelize (str) {
        // Use the replace method with a regular expression to match non-word characters followed by any character
        // The regular expression /\W+(.)/g matches one or more non-word characters followed by any character, capturing the character
        // For each match, a callback function is invoked with 'match' representing the entire match and 'chr' representing the captured character
        return  (" " + str).toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, function(match, chr) {
            return chr.toUpperCase();
        });
      },
      checkingMolaSubscription() {
        if(!this.whitelistMolaSubs.includes(this.getUser.mola_subscription)) {
          return -1;
        }
        if(!this.whitelistMolaSubs.includes(this.username.mola_subscription)) {
          return 0;
        }
        return 1;
      },
      async cancelDuel() {
        this.isLoadingCancel = true;

        try {
          const batch = db.batch();
          const duelDocRef = db.collection('duel_invitation').doc(this.duelInfo.id);
          batch.set(duelDocRef, {
            status: -2,
            invite_updated_at: new Date().getTime(),
          }, { merge: true });

          const notificationsQuerySnapshot = await db.collection('notifications')
            .where('ref_id', '==', this.duelInfo.id)
            .where('type', '==', 'duel_invitation')
            .where('sender_uid', '==', this.getUser.uid)
            .get();

          if (!notificationsQuerySnapshot.empty) {
            const notificationDocRef = db.collection('notifications').doc(notificationsQuerySnapshot.docs[0].id);
            batch.set(notificationDocRef, {
              status: -1,
              action_expired: new Date().getTime(),
              modified: new Date().getTime(),
            }, { merge: true });
          }

          await batch.commit();
          console.log('Success cancel duel')
          this.message = 'Undangan duel berhasil dibatalkan';
          this.snackbar = true;
          this.isLoadingCancel = false;
          this.confirmationDialog = false;
          this.isActive = false;
          this.isRandom = false;
          this.randomName = null;
          this.isRandomLoading = false;
          this.isButtonActive = true;
          this.isUserAlreadyInDuel = false;
        } catch (error) {
          console.log("Batch write failed: ", error);
          this.isLoadingCancel = false;
          // Handle error accordingly
        }
      },
      async sendDuelInvitation(){
        this.isLoading = true;
        this.isInvited = false; 
        const isUserOrOpponentAlreadyInDuel = await this.checkIsDuelAlreadyExists2();
        const isValidMolaSubscription = this.checkingMolaSubscription();
        console.log(isValidMolaSubscription);
        if (isUserOrOpponentAlreadyInDuel) {
          this.message = this.username.name + ' ' + this.lang[this.getLanguage].DUEL_USER_ALREADY_INVITED
          this.snackbar = true;
          this.isInvited = true; 
          this.isLoading = false;
          return;
        }

        if (isValidMolaSubscription !== 1) {
          this.message = isValidMolaSubscription === 0 ? 
            this.lang[this.getLanguage].OPPONENT_NOT_MOLA_SUBSCRIPTION :
            this.lang[this.getLanguage].DUEL_USER_MUST_BE_MOLA_SUBSCRIPTION
          this.snackbar = true;
          this.isLoading = false;
          return;
        }

        let postkey = db.collection('duel_invitation').doc();
        db.collection('duel_invitation')
          .doc(postkey.id)
          .set({
            id: postkey.id,
            event_doc_id: this.getEventList[0].doc_id,
            event_name: this.getEventList[0].name,
            uid: this.getUser.uid,
            user_name: this.getUser.name,
            user_mola_subscription: this.getUser.mola_subscription,
            opponent_id: this.username.uid,
            opponent_username: this.username.name,
            opponent_mola_subscription: this.username.mola_subscription,
            helper_uid: [this.username.uid, this.getUser.uid],
            created_by: this.getUser.name,
            created: new Date().getTime(),
            modified_by: '',
            modified: 0,
            invite_updated_at: 0,
            status: 0 // 0: Invited, 1: Accepted, -1: Rejected, -2: Cancelled
          })
          .then(() => {
            console.log('duel_invitation in bucket created');
            // let expired = this.getEventList[0].fights.sort((a, b) => a.start - b.start) - (1000 * 60 * 60);
            const firstFight = this.getEventList[0].fights.reduce((min, obj) => {
              return obj.start < min.start ? obj : min;
            });
            let postkey2 = db.collection('notifications').doc();
            console.log(this.getEventList[0].fights);
            db.collection('notifications')
              .doc(postkey2.id)
              .set({
                id: postkey2.id,
                type: 'duel_invitation',
                sender_uid: this.getUser.uid,
                sender_username: this.getUser.name,
                receiver_uid: this.username.uid,
                receiver_username: this.username.name,
                ref_id: postkey.id,
                accepted: 0,
                title: {
                  id: `Undangan Duel!`,
                  en: `Duel Invitation!`,
                  it: `Invito al duello!`,
                },
                message: {
                  id: `${this.getUser.name} telah mengundang Anda berduel untuk pertandingan ${this.getEventList[0].name}! Maukah kamu menerimanya?`,
                  en: `${this.getUser.name} has invited you to duel for ${this.getEventList[0].name}! Will you accept it?`,
                  it: `${this.getUser.name} ti ha invitato a duellare per ${this.getEventList[0].name}! Lo accetterai?`,
                },
                outgoing_title: `Undangan Duel Telah Dikirimkan`,
                outgoing_message: `Anda telah mengirimkan undangan duel ke ${this.username.name} untuk pertandingan ${this.getEventList[0].name}`,
                action_expired: firstFight.start,
                actions: [
                  {
                    name: 'ACCEPT',
                    color: 'green',
                    bucket: 'duel_invitation',
                    id: postkey.id,
                    payload: {
                      status: 1
                    },
                    notification:{
                      title: {
                        id: `Undangan Duel Diterima!`,
                        en: `Duel Invitation Accepted!`,
                        it: `Invito al duello accettato!`,
                      },
                      message: {
                        id: `${this.username.name} telah menerima undangan duel Anda untuk pertandingan ${this.getEventList[0].name}!`,
                        en: `${this.username.name} has accepted your duel invitation for ${this.getEventList[0].name}!`,
                        it: `${this.username.name} ha accettato il tuo invito al duello per ${this.getEventList[0].name}!`,
                      },
                    }
                  },
                  {
                    name: 'REJECT',
                    color: 'red',
                    bucket: 'duel_invitation',
                    id: postkey.id,
                    payload: {
                      status: -1
                    },
                    notification:{
                      title: {
                        id: `Undangan Duel Ditolak!`,
                        en: `Duel Invitation Rejected!`,
                        it: `Invito al duello rifiutato!`,
                      },
                      message: {
                        id: `Undangan duel Anda untuk pertandingan ${this.getEventList[0].name} ditolak oleh ${this.username.name}. Coba lagi pada pertandingan selanjutnya!`,
                        en: `${this.username.name} has rejected your duel invitation for ${this.getEventList[0].name}!`,
                        it: `${this.username.name} è stato rifiutato il tuo invito al duello per ${this.getEventList[0].name}!`,
                      },
                    }
                  }
                ],
                created_by: this.getUser.name,
                created: new Date().getTime(),
                modified_by: '',
                modified: new Date().getTime(),
                status: 0 // 0: Sent, 1: Read, 2: Action is already clicked, -1: Cancelled
              }).then(() => {
                console.log('notification in bucket created');
                this.isLoading = false;
                this.step = 2;
              }).catch(err => {
                console.log(err)
              }).finally(() => {
                this.isLoading = false;
                this.step = 2;
              })
            
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          })
          .finally(() => {
            this.isLoading = false;
          })
      },
      async checkIsDuelAlreadyExists(){
        console.log('######### checking duel invites ########')
        let isUserAlreadyInviteOrInvited = false;
        // let isOpponentAlreadyInviteOrInvited = false;

        const userQuery = db.collection('duel_invitation')
          .where('status', 'in', [0, 1])
          .where('event_doc_id', '==', this.getEventList[0].doc_id)
          .where('helper_uid', 'array-contains', this.getUser.uid)

        const userDocs = await userQuery.get();
        console.log('######### userDocs ##########')
        console.log(userDocs)
        if (!userDocs.empty) {
          isUserAlreadyInviteOrInvited = true;
          this.duelInfo = userDocs.docs[0].data()
        }

        this.duelInfoObs = userQuery.onSnapshot(snapshot => {
          console.log('Status: ', this.duelInfo.status);
          this.duelInfo = snapshot.docs[0].data();
          console.log('Updated Status: ', this.duelInfo.status);
        })
        
        /* const opponentDocs = await db.collection('duel_invitation')
          .where('status', 'in', [0, 1])
          .where('event_doc_id', '==', this.getEventList[0].doc_id)
          .where('helper_uid', 'array-contains', this.username.uid)
          .get();
        console.log('######### opponentDocs ##########')
        console.log(opponentDocs)
        if (!opponentDocs.empty) {
          isOpponentAlreadyInviteOrInvited = true;
        } */

        return isUserAlreadyInviteOrInvited;
      },
      async checkIsDuelAlreadyExists2(){
        console.log('######### checking duel invites ########')
        let isUserAlreadyInviteOrInvited = false;
        let isOpponentAlreadyInviteOrInvited = false;

        const userDocs = await db.collection('duel_invitation')
          .where('status', 'in', [0, 1])
          .where('event_doc_id', '==', this.getEventList[0].doc_id)
          .where('helper_uid', 'array-contains', this.getUser.uid)
          .get();
        console.log('######### userDocs ##########')
        console.log(userDocs)
        if (!userDocs.empty) {
          isUserAlreadyInviteOrInvited = true;
          this.duelInfo = userDocs.docs[0].data()
        }

        const opponentDocs = await db.collection('duel_invitation')
          .where('status', 'in', [0, 1])
          .where('event_doc_id', '==', this.getEventList[0].doc_id)
          .where('helper_uid', 'array-contains', this.username.uid)
          .get();
        console.log('######### opponentDocs ##########')
        console.log(opponentDocs)
        if (!opponentDocs.empty) {
          isOpponentAlreadyInviteOrInvited = true;
        }

        return isUserAlreadyInviteOrInvited || isOpponentAlreadyInviteOrInvited;
      },
      closeDuelDialog() {
        this.username = []
        this.isActive = false;
        this.isRandom = false;
        this.randomName = null;
        this.isRandomLoading = false;
        this.isButtonActive = true;
        this.dialog = false
        this.interval;
        this.isInvited = false; 
        setTimeout(() => {
          this.step = 1;  
        }, 1000);
      },
      sendNotification(){
          return;
      },
      closeSnackbar(){
        this.message = '';
        this.snackbar = false;
      },
      async getPaidUser(){
        const paidUser = [];
        const userDuelDocs = await db.collection('duel_invitation')
          .where('status', 'in', [0, 1])
          .where('event_doc_id', '==', this.getEventList[0].doc_id)
          .get();
        const duelSet = new Set();
        userDuelDocs.forEach(doc => {
          duelSet.add(doc.data().uid)
          duelSet.add(doc.data().opponent_id)
        })
        const duelArr = Array.from(duelSet);

        const userDocs = await db.collection('users')
          .where('mola_subscription', '==', 1) //Only Include mola subs 1 to random array
          .get();
        userDocs.forEach((doc) => {
          const obj = doc.data();
          if(!duelArr.includes(obj.uid) && obj.email.split('@')[1] !== 'mola.tv'){
            paidUser.push({
              uid: obj.uid,
              mola_subscription: obj.mola_subscription, 
              name: obj.name,
              full_name: obj.full_name,
              avatar: obj.avatar,
              email: obj.email,
            })
          }
        })
        return paidUser;
      },
      async randomize(){
        this.isInvited = false; 
        this.isRandomLoading = true;
        const users = await this.getPaidUser();
        this.randoms = users//.map(item => { return item.name; })
        console.log('randoms', this.randoms)
        this.numb = this.randoms.length;
        this.currentIndex = this.getRandomInt(this.numb);
        this.again();
      },
      getRandom() {
        if (this.isRandom == true) {
            this.currentIndex++;
            if (this.currentIndex >= this.numb) {
                this.currentIndex = 0;
            }
            this.count++;
            if (
                this.count > this.maximumCount ||
                (this.count > this.minimumCount && Math.random() > 0.6)
            ) {
                clearInterval(this.interval);
                this.currentIndex = this.stopIndex;
                console.log('Stop', this.currentIndex, this.randoms[this.currentIndex].name)
                this.getName(this.randoms[this.stopIndex]);
                this.isRandomLoading = false;
                this.isRandom = false;
            } else {
              console.log('Loop', this.currentIndex, this.randoms[this.currentIndex].name)
              this.randomName = this.randoms[this.currentIndex];
              this.stopIndex = this.currentIndex;
            }
        }
        },

        again() {
            this.count = 0;
            this.selectedName = null;
            this.isButtonActive = false;
            this.isActive = true;
            this.isRandom = true;
            this.interval = setInterval(this.getRandom(), 120);
        },

        getRandomInt(max) {
            return Math.floor(Math.random() * Math.floor(max));
        },

        getName(name) {
            console.log('getName', name, [null, undefined, ''].includes(name), this.isRandom);
            if (![null, undefined, ''].includes(name) && this.isRandom === true) {
                this.isButtonActive = true;
                this.isRandom = false;
                this.selectedName = name;
                this.username = this.randoms.find(item => item.name === name.name);
            }
        },

        gotoLink(link) {
          window.open(link, "_blank");
        },

        handleSuccess(e) {
          console.log(e);
          navigator.clipboard.writeText(e.text);
          this.message = 'Copied to clipboard.'
          this.snackbar = true
        },
        handleError(e) {
          console.log(e);
        },
    },
  };
  </script>
  <style>
    @font-face {
      font-family: 'Furore';
      font-style: normal;
      font-weight: normal;
      src: local('Furore'), url('../../assets/Furore.woff') format('woff');
    }
    .duelTitle {
        font-family: 'Righteous', cursive;
        font-size:36px;
        font-weight: 500;
        text-transform: uppercase;
    }

    .v-autocomplete.v-select--is-menu-active .v-input__icon--append .v-icon {
        transform: none !important;
    }

    .v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active.v-autocomplete__content {
        border-radius: 25px;
    }

    .text-yellow-mola {
        color: #cdb15f;
    }

    
.random {
    width: 100%;
    height: 80px;
    text-align: center;
    -webkit-perspective: 700px;
    perspective: 700px;
}

.random2 {
    position: absolute;
    text-align: center;
    transform-origin: 0 100%;
    font-size: 40px;
}

.current {
    transition: all 0.2s ease-out;
    opacity: 1.0;
    text-align: center;
}

.in {
    text-align: center;
    opacity: 0.0;
    transform: rotate3d(1, 0, 0, -90deg);
}

.out {
    transition: all 0.3s ease-out;
    text-align: center;
    opacity: 0.0;
    transform: rotate3d(1, 0, 0, 90deg);
}
  </style>