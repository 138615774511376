import { render, staticRenderFns } from "./SharePredictionDialog.vue?vue&type=template&id=327f24e0&scoped=true"
import script from "./SharePredictionDialog.vue?vue&type=script&lang=js"
export * from "./SharePredictionDialog.vue?vue&type=script&lang=js"
import style0 from "./SharePredictionDialog.vue?vue&type=style&index=0&id=327f24e0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "327f24e0",
  null
  
)

export default component.exports