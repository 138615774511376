import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/home',
    name: 'Home',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Home2.vue'),
  },
  {
    path: '/authorise/:code?',
    name: 'Authorise',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Authorise.vue'),
  },
  {
    path: '/schedule',
    name: 'Broadcast Schedule',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/users/BroadcastSchedule.vue'
      ),
  },
  {
    path: '/leaderboard',
    name: 'Leaderboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/users/Leaderboard.vue'),
  },

  // ########################################################################################
  // ##################################### App User Views ###################################
  // ########################################################################################
  {
    path: '/myaccount',
    name: 'myaccount',
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/users/MyAccHome.vue'),
  },
  {
    path: '/myprofile',
    name: 'myprofile',
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/users/MyProfile.vue'),
  },
  {
    path: '/news/:slug?',
    name: 'News',
    meta: {
      requiresAuth: false,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/users/News.vue'),
  },
  {
    path: '/faq',
    name: 'FAQ',
    meta: {
      requiresAuth: false,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/users/FAQ.vue'),
  },
  // ###########################################################################################
  // ################################### Admin Console Routes ##################################
  // ###########################################################################################
  {
    path: '/admin',
    name: 'Admin Console',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/admin/AdminHome.vue'),
  },
  {
    path: '/reporting_dashboard',
    name: 'Reporting Dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/admin/reporting-dashboard/ReportingDashboardHome.vue'),
  },
  {
    path: '/admevents',
    name: 'Admin Events',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/admin/Events.vue'),
  },
  {
    path: '/admresults',
    name: 'Event Results',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/admin/Results.vue'),
  },
  {
    path: '/admnewevent',
    name: 'Create Event',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/admin/EventCreate.vue'),
  },
  {
    path: '/admin/login',
    name: 'Admin Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/admin/Login.vue'),
  },
  {
    path: '/admin/news',
    name: 'Admin News',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/admin/News.vue'),
  },
  {
    path: '/admin/homepage',
    name: 'Admin Homepage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/admin/Homepage.vue'),
  },
  /* {
    path: '/admin/delete',
    name: 'Admin Delete',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/admin/DeleteOperations.vue'),
  }, */
  {
    path: '/admin/prizes',
    name: 'Admin Prize Catalog',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/admin/Catalog.vue'),
  },
  {
    path: '/admin/recalculate',
    name: 'Admin Recalculate',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/admin/Recalculate.vue'),
  },
  {
    path: '/admin/config',
    name: 'Admin Config',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/admin/Config.vue'),
  },
  {
    path: '/admin/reporting',
    name: 'Admin Reporting',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/admin/Reporting.vue'),
  },
  {
    path: '/admin/predictionreports',
    name: 'Prediction Analytics',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/admin/PredictionAnalytics.vue'
      ),
  },
  {
    path: '/admin/predictionwinners',
    name: 'Prediction Winners Report',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/admin/PredictionWinnersReport.vue'
      ),
  },
  {
    path: '/admin/subscriberreports',
    name: 'Mola Subscriber Report',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/admin/MolaSubscriberReport.vue'
      ),
  },
  {
    path: '/notification',
    name: 'Notification',
    component: () => import('../components/notifications/NotificationList.vue'),
  },
  {
    path: '/howtoplay',
    name: 'How to Play',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/users/HowToPlay.vue'),
  },
  {
    path: '/watch_the_match',
    name: 'Watch Matches',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/users/WatchMatch.vue'),
  },
  {
    path: '/admin/watch',
    name: 'Watch Management',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/admin/WatchMatch.vue'),
  },
  {
    path: '/admin/reward',
    name: 'Reward Management',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/admin/Rewards.vue'),
  },
  {
    path: '/admin/duel-realtime',
    name: 'Duel Real Time',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/admin/duel-dashboard/DuelRealTime.vue'
      ),
  },
  {
    path: '/admin/duel-summary',
    name: 'Weekly Duel Summary',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/admin/duel-dashboard/DuelWeeklySummary.vue'
      ),
  },
  {
    path: '/admin/weekly-points-redemption',
    name: 'Weekly Redeemed and Unredeemed Points',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/admin/player-reports/WeeklyRedemptionReport.vue'
      ),
  },
  {
    path: '/admin/weekly-new-player',
    name: 'Weekly New Player Report',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/admin/player-reports/NewPlayerSummary.vue'
      ),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
